/*!

=========================================================
* Purity UI Dashboard - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/purity-ui-dashboard/blob/master/LICENSE.md)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import VimeoImporter from "views/VimeoImporter";
import HowToGetToken from "views/HowToGetToken";
import { ChakraProvider } from "@chakra-ui/react";
import './index.css'

ReactDOM.render(
  <ChakraProvider>
    <BrowserRouter>
      <Switch>
        <Route path={`/`} component={VimeoImporter} exact/>
        <Route path={`/how-to-get-token`} component={HowToGetToken} />
      </Switch>
    </BrowserRouter>
  </ChakraProvider>,
  document.getElementById("root")
);
