import { useEffect, useMemo, useRef, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormLabel,
    FormControl,
    Input,
    Image,
    Box,
    Badge,
    Grid,
    Text,
    Checkbox,
    Stack,
    Spinner,

    useDisclosure,
    useToast,
    Flex
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import axios from 'axios'

const AUTHORIZE_MODAL = 0;
const FINALIZE_MODAL = 1;

const status = {
    UNDEFINED_STATUS: -1,
    IN_PROGRESS: 0,
    COMPLETED: 1
}

const downloadStatus = {
    UNDEFINED_STATUS: -1,
    DOWNLOADING: 0,
    FINISHED: 1
}

const bytesToFormal = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const download = async(uri) => {
    const outsideRes = await fetch(uri);
    const blob = await outsideRes.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "report.csv";
    link.click();
}

const getJSON = (url) => {
    fetch(url)
        .then(res => res.json())
        .then(out => {
            const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(out));
            const link = document.createElement("a");
            link.setAttribute("href", dataStr);
            link.setAttribute("download", "report.json");
            link.click();
        })
        .catch(err => { throw err });
};

export default function VimeoImporter() {
    const [accessToken, setAccessToken] = useState('')
    const [loadingVideos, setLoadingVideos] = useState(true)
    const [videos, setVideos] = useState([])
    const [downloadedVideos, setDownloadedVideos] = useState([])
    const [modalType, setModalType] = useState(AUTHORIZE_MODAL)
    const [userId, setUserId] = useState(-1)
    const [downloadingText, setDownloadingText] = useState('')
    const [downloadingStatus, setDownloadingStatus] = useState(-1)
    const [reportCSV, setReportCSV] = useState('')
    const [reportJson, setReportJson] = useState('')
    const selectedVideosCount = useMemo(() => {
        return videos.filter(({ selected }) => selected).length
    }, [videos])
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef()
    const toast = useToast()

    useEffect(() => {
        if (accessToken) return
        onOpen()
    }, [])

    const selectedAll = useMemo(() => {
        return (videos.length - downloadedVideos.length) === selectedVideosCount && (videos.length > downloadedVideos.length)
    }, [videos, downloadedVideos, selectedVideosCount])

    const selectVideoTotalSize = useMemo(() => {
        if (!videos.length) return '0 Byte'
        return bytesToFormal(videos.filter(({ selected }) => selected).map(({ size }) => size).reduce((a, b) => a + b, 0))
    }, [videos])

    const getDownloadedVideos = (userId, token) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_BE_BASE_URL}/vimeo/${userId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(({ data }) => {
                const { downloaded, reports: { csv, json } } = data
                setReportCSV(`${process.env.REACT_APP_REPORT_URL}${csv}`)
                setReportJson(`${process.env.REACT_APP_REPORT_URL}${json}`)
                resolve(downloaded)
            }).catch((error) => {
                reject(error)
            })
        })
    }

    const getUserInfo = (token) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: 'https://api.vimeo.com/me',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((response) => {
                const { uri, name, link, account } = response.data
                const id = uri.split('/')[2]

                resolve({ id, name, link, account })
            }).catch((error) => {
                console.log(error)
                resolve(null)
            })
        })
    }

    const getVimeoVieos = async (token) => {
        const getVideos = (pageNo = 1) => {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: `https://api.vimeo.com/me/videos?page=${pageNo}&per_page=${25}`,
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then((response) => {
                    const { data } = response
                    const videos = data.data

                    resolve(videos.filter(({ type, files }) => type != 'live' && files.length).map(({ uri, name, link, pictures: { base_link }, files, width, height, duration }) => {
                        const totalSize = files.map(({ size }) => size).reduce((a, b) => a + b)
                        const totalSizeShort = bytesToFormal(totalSize)
                        const durationStr = ((e) => {
                            const h = Math.floor(e / 3600).toString(),
                                m = Math.floor(e % 3600 / 60).toString(),
                                s = Math.floor(e % 60).toString();
                      
                            return `${h > 0 ? `${h}h ` : ''}${m > 0 ? `${m}m ` : ''}${`${s}s`}`;
                        })(duration)
                        const renditions = files.filter(({ rendition }) => rendition != 'adaptive').map(({ rendition, size_short }) => `${rendition} (${size_short})`)
                        
                        return { id: parseInt(uri.split('/')[2]), uri, name, link, posterImg: base_link, size: totalSize, totalSizeShort, duration: durationStr, renditions,selected: true }
                    }))
                }).catch((error) => {
                    if (error.response?.data?.error_code === 2286)
                        return resolve([])
                    else
                        return reject(error)
                })
            })
        }

        const getEntireVideos = async (pageNo = 1) => {
            const result = await getVideos(pageNo);

            if (result.length > 0) {
                return result.concat(await getEntireVideos(pageNo + 1))
            } else {
                return result
            }
        }

        const __videos = await getEntireVideos();
        return __videos
    }

    const handleSelectAll = (selected) => {
        const videosExceptDownloaded = videos.map((video) => ({ ...video, selected: downloadedVideos.includes(video.id) ? false : selected}))

        setVideos(videosExceptDownloaded)
    }

    const handleAuthorize = async () => {
        const user = await getUserInfo(accessToken)

        if (!user) return toast({
            title: 'Invalid token',
            description: "Token you typed is invalid. Please type valid one.",
            status: 'error',
            duration: 3000,
            isClosable: true,
        })

        setUserId(user.id)
        onClose()
        setLoadingVideos(true)

        const downloaded = await getDownloadedVideos(user.id, accessToken)
        setDownloadedVideos(downloaded)
        const originVideos = await getVimeoVieos(accessToken)
        const videosExceptDownloaded = originVideos.map((video) => ({ ...video, selected: !downloaded.includes(video.id)}))

        setLoadingVideos(false)
        setVideos(videosExceptDownloaded)
    }

    const handleVideoClick = (index, checked) => {
        let t_videos = [...videos]
        t_videos[index].selected = checked
        setVideos(t_videos)
    }

    const finalize = () => {
        setModalType(FINALIZE_MODAL)
        onOpen()
    }

    const handleFinalize = () => {
        const videoIds = videos.filter(({ selected }) => selected).map(({ id }) => parseInt(id))

        if (!videoIds.length) {
            toast({
                position: 'top-right',
                title: 'No videos selected',
                description: `Select videos to download.`,
                status: 'error',
                duration: 1000,
                isClosable: true,
            })
            return
        }

        setDownloadingStatus(downloadStatus.DOWNLOADING)
        setDownloadingText(`Downloading`)
        axios.post(`${process.env.REACT_APP_BE_BASE_URL}/vimeo/download`, {
            accessToken,
            videos: videoIds,
            userId
        }).then(() => {
            const timer = setInterval(() => {
                const query=videoIds.join(',')
                axios.get(`${process.env.REACT_APP_BE_BASE_URL}/getStatus?videos=${query}`)
                    .then(({ data }) => {
                        const totallyFinished = data.map(({ status }) => status).reduce((a, b) => a && (b === downloadStatus.FINISHED), true)
                        const numDonloaded = data.filter(({ status }) => status === downloadStatus.FINISHED).length

                        setDownloadingText(`Downloading (${videoIds.length} / ${numDonloaded})`)
                        if (totallyFinished) {
                            const downloaded = [...downloadedVideos, ...videoIds]
                            const videosExceptDownloaded = videos.map((video) => ({ ...video, selected: downloaded.includes(video.id) ? false : video.selected}))
                            setDownloadedVideos(downloaded)
                            setVideos(videosExceptDownloaded)

                            toast({
                                position: 'top-right',
                                title: 'Download Success',
                                description: `${videoIds.length} videos is downloaded successfully.`,
                                status: 'success',
                                duration: 5000,
                                isClosable: true,
                            })
                            onClose()
                            clearInterval(timer)
                            setDownloadingText('')
                            setDownloadingStatus(downloadStatus.FINISHED)
                            getDownloadedVideos(userId, accessToken)
                        }
                    })
                    .catch(() => {
                        clearInterval(timer)
                    })
            }, [4000])
        }).catch(() => {

        })
    }

    const videoList = useMemo(() => {
        return (
            <Grid templateColumns='repeat(4, 1fr)' gap={1}>
                {videos.map(({ id, posterImg, name, selected, size, renditions, duration }, index) => (
                    <div className='flex justify-center mb-2' key={index}>
                        <Box width='320px' borderWidth='1px' borderRadius='lg' overflow='hidden' >
                            {downloadedVideos.includes(id)
                                ?
                                <>
                                    <Badge colorScheme='red' className='m-2' fontSize="md">Downloaded</Badge>
                                    <Image src={posterImg} alt={name} className="object-contain" height="150px" width="100%" />

                                    <Box p='6'>
                                        <Box mt='1' fontWeight='semibold' as='h4' lineHeight='tight' noOfLines={1}>{`Name: ${name}`}</Box>
                                        <Box mt='1' fontWeight='semibold' as='h4' lineHeight='tight' noOfLines={1}>{`Total size: ${bytesToFormal(size)}`}</Box>
                                        <Box mt='1' fontWeight='semibold' as='h4' lineHeight='tight' noOfLines={1}>{`Duration: ${duration}`}</Box>
                                        <Box mt='1' fontWeight='semibold' as='h4' lineHeight='tight' noOfLines={1}>Renditions:</Box>
                                        {renditions.map((rend) => <Badge className='mx-2' colorScheme='green'>{rend}</Badge>)}
                                    </Box>
                                </>
                                :
                                <Checkbox colorScheme='green' isChecked={selected} className='m-2 !block' onChange={(e) => handleVideoClick(index, e.target.checked)}>
                                    <Image src={posterImg} alt={name} className="object-contain" height="150px" width="100%" />

                                    <Box p='6'>
                                        <Box mt='1' fontWeight='semibold' as='h4' lineHeight='tight' noOfLines={1}>{`Name: ${name}`}</Box>
                                        <Box mt='1' fontWeight='semibold' as='h4' lineHeight='tight' noOfLines={1}>{`Total size: ${bytesToFormal(size)}`}</Box>
                                        <Box mt='1' fontWeight='semibold' as='h4' lineHeight='tight' noOfLines={1}>{`Duration: ${duration}`}</Box>
                                        <Box mt='1' fontWeight='semibold' as='h4' lineHeight='tight' noOfLines={1}>Renditions:</Box>
                                        {renditions.map((rend) => <Badge className='mx-2' colorScheme='green'>{rend}</Badge>)}
                                    </Box>
                                </Checkbox>
                            }
                        </Box>
                    </div>
                ))}
            </Grid>
        )
    }, [videos])

    return (
        <>
            <Modal closeOnOverlayClick={false} initialFocusRef={initialRef} onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {modalType === AUTHORIZE_MODAL &&
                            <>
                                <div>Authorize your vimeo account</div>
                                <Link to="/how-to-get-token" target="_blank" rel="noopener noreferrer" className="-blue-500 underline text-xs">how to generate your access token</Link>
                            </>
                        }
                        {modalType === FINALIZE_MODAL &&
                            <div>Finalize your download</div>
                        }
                    </ModalHeader>
                    {modalType === FINALIZE_MODAL && <ModalCloseButton isDisabled={downloadingStatus === downloadStatus.DOWNLOADING} />}
                    <ModalBody>
                        {modalType === AUTHORIZE_MODAL &&
                            <FormControl>
                                <FormLabel>Enter your Vimeo access token</FormLabel>
                                <Input ref={initialRef} onChange={(e) => setAccessToken(e.target.value)} />
                            </FormControl>
                        }
                        {modalType === FINALIZE_MODAL &&
                            <FormControl>
                                <FormLabel>{`You select ${selectedVideosCount} videos`}</FormLabel>
                                <FormLabel>{`Total size is ${selectVideoTotalSize}.`}</FormLabel>
                            </FormControl>
                        }
                    </ModalBody>
                    <ModalFooter>
                        {modalType === AUTHORIZE_MODAL &&
                            <Button onClick={handleAuthorize}>Authorize</Button>
                        }
                        {modalType === FINALIZE_MODAL &&
                            <Button onClick={() => handleFinalize()} isDisabled={downloadingStatus === downloadStatus.DOWNLOADING}>
                            {downloadingStatus === downloadStatus.DOWNLOADING
                                ? downloadingText
                                : 'Finalize'
                            }
                            </Button>
                        }
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {isOpen && modalType === AUTHORIZE_MODAL
                ? null
                :
                <div className='p-4 fixed top-0 w-full z-10 rounded-b-xl' style={{ backgroundColor: 'rgb(42 41 41 / 30%)' }}>
                    {loadingVideos
                        ?
                        <Text className='flex justify-center items-center'>
                            <Spinner
                                thickness='8px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='lg'
                                className='mr-3'
                            />
                            Getting Videos
                        </Text>
                        :
                        <div className='flex justify-between items-center'>
                            <Text fontSize='2xl' as='i' color='white' >{`Videos to import (${selectedVideosCount})`}</Text>
                            <Button onClick={() => finalize()}>Download</Button>
                        </div>
                    }
                </div>
            }

            {loadingVideos
                ? null
                :
                <div className='p-1 mt-20'>
                    <Flex justifyContent='space-between'>
                        <Checkbox isChecked={selectedAll} size='lg' colorScheme='green' defaultChecked className='ml-4 mb-3' onChange={(e) => handleSelectAll(e.target.checked)}>
                            <Text as='b' color='gray.500'> Select All </Text>
                        </Checkbox>
                        <Flex>
                            <Text cursor='pointer' className='no-underline hover:underline mr-1' onClick={() => download(reportCSV)}>CSV</Text>|
                            <Text cursor='pointer' className='no-underline hover:underline ml-1' onClick={() => getJSON(reportJson)}>JSON</Text>
                        </Flex>
                    </Flex>
                    {videoList}
                </div>
            }
        </>
    )
}
